import React, { Component } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS

const customIcon = L.icon({
    iconUrl: 'http://raw.githubusercontent.com/Leaflet/Leaflet/main/dist/images/marker-icon.png', // Adjust the path
    iconSize: [32, 32], // Adjust the size
    iconAnchor: [16, 32], // Adjust the anchor point
});

class Map extends Component {
    componentDidMount() {
        // Create a map centered at [latitude, longitude] with a certain zoom level
        this.map = L.map('map', {
            center: [6.8510180,80.0417940],
            zoom: 13,
        });

        // Add a tile layer from a provider (OpenStreetMap)
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; OpenStreetMap contributors',
        }).addTo(this.map);


        L.marker([6.8510180,80.0417940],{ icon: customIcon }).addTo(this.map)
            .bindPopup('Softwareloom')
            .openPopup();    }

    render() {
        return <div id="map" style={{ width: '100%', height: '100%' }}></div>;
    }
}

export default Map;
