import React from 'react';
import { Element } from 'react-scroll';
import Navbar from './global-components/navbar';
import SliderV1 from './section-components/slider-v1';
import SoftBoxAbout from './section-components/soft-box-about';
import Hosting from './section-components/hosting';
import Team from './section-components/team';
import Client from './section-components/client';
import ContactForm from './section-components/contact-form';
 
import FooterV2 from './global-components/footer-v2';

const Home_V2 = () => {
    return <div>
        <Navbar />
        <SliderV1 />
        <SoftBoxAbout />
        <Hosting />
        <Client />
        <Team />
        <ContactForm />
        <FooterV2 />
    </div> 
}

export default Home_V2

