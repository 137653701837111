import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';


class Hosting extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'image'
        let data = sectiondata.about
        let customclass = this.props.customclass ? this.props.customclass : ''

    return <div>
            <div className="hosting-type-area pd-top-120">
  <div className="container">
    <div className="row">
      <div className="col-lg-3">
        <ul className="nav nav-tabs sb-hosting-tabs">
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#d_tabs_1"><img src={publicUrl+"assets/img/icons/13a.png"} alt="img" />Software Development</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#d_tabs_2"><img src={publicUrl+"assets/img/icons/14a.png"} alt="img" />Web Development</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#d_tabs_3"><img src={publicUrl+"assets/img/icons/15a.png"} alt="img" />Mobile App Development</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#d_tabs_4"><img src={publicUrl+"assets/img/icons/16a.png"} alt="img" />DevOps</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#d_tabs_5"><img src={publicUrl+"assets/img/icons/17a.png"} alt="img" />QA Testing</a>
          </li>
        </ul>
      </div>
      <div className="col-lg-9">
        <div className="tab-content">
          <div className="tab-pane fade show active" id="d_tabs_1">
            <div className="single-hosting-content">
              <div className="row">
                <div className="col-lg-3">
                  <div className="section-title style-two hosting-tab-title">
                    <h6 className="title">Software <span>Development</span></h6>
                  </div>
                  <p>We provide result-driven software development services for small, mid-sized, and enterprise-level
                    businesses, offering cost-effective solutions, streamlined systems, increased productivity and secure software tailored to meet specific requirements.</p>
                  <p></p>
                </div>
                <div className="col-lg-4">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/others/20.png"} alt="img" />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="riyaqas-check-list-wrap">
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Custom Software Development Services</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Enterprise Software Development Services</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Startup / MVP Software Development</span>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="d_tabs_2">
            <div className="single-hosting-content">
              <div className="row">
                <div className="col-lg-3">
                  <div className="section-title style-two">
                    <h6 className="title">Web<span> Development</span></h6>
                  </div>
                  <p>We specialize in delivering high-quality services for creating web products to leading companies across
                    various domains. </p>
                  <p>Our process ensures the highest customer satisfaction with clear results. Partner with
                    us for comprehensive web development services.</p>
                </div>
                <div className="col-lg-4">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/others/20.png"} alt="img" />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="riyaqas-check-list-wrap">
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>UI/UX Design</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Full Stack Development</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Web Application Development</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>eCommerce Web Development</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Enterprise Web Development</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Custom API Development</span>
                    </div>
                    <div className="riyaqas-check-list media mb-0">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Cloud Application Development Service</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="d_tabs_3">
            <div className="single-hosting-content">
              <div className="row">
                <div className="col-lg-3">
                  <div className="section-title style-two">
                    <h6 className="title">Mobile Application <span>Development</span></h6>
                  </div>
                  <p>All our mobile app development work is a result of continuous research, unceasing creativity, and
                    scrupulous execution.</p>
                  <p>Our experienced in-house teams ensure high-level product development to foster
                    your business&#39;s success.</p>
                </div>
                <div className="col-lg-4">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/others/20.png"} alt="img" />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="riyaqas-check-list-wrap">
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>ios Application Development</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Android Application Development</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Cross-platform Application Development</span>
                    </div>
                    {/*<div className="riyaqas-check-list media">*/}
                    {/*  <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />*/}
                    {/*  <span>Powered by top of the line enterprise</span>*/}
                    {/*</div>*/}
                    {/*<div className="riyaqas-check-list media">*/}
                    {/*  <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />*/}
                    {/*  <span>Manage web apps more collaboratively</span>*/}
                    {/*</div>*/}
                    {/*<div className="riyaqas-check-list media">*/}
                    {/*  <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />*/}
                    {/*  <span>We are committed to deliver fast</span>*/}
                    {/*</div>*/}
                    {/*<div className="riyaqas-check-list media mb-0">*/}
                    {/*  <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />*/}
                    {/*  <span>Manage web apps more collaboratively</span>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="d_tabs_4">
            <div className="single-hosting-content">
              <div className="row">
                <div className="col-lg-3">
                  <div className="section-title style-two">
                    <h6 className="title">Dev<span>Ops</span></h6>
                  </div>
                  <p>Our DevOps approach incorporates CI/CD pipeline to accelerate software delivery, ensuring efficiency
                    and collaboration between teams.</p>
                  <p></p>
                </div>
                <div className="col-lg-4">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/others/20.png"} alt="img" />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="riyaqas-check-list-wrap">
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Planning &amp; Assessment</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Continuous Implementation &amp; Continuous Deployment</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Process Automation</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Security Protocols</span>
                    </div>
                    {/*<div className="riyaqas-check-list media">*/}
                    {/*  <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />*/}
                    {/*  <span>Powered by top of the line enterprise</span>*/}
                    {/*</div>*/}
                    {/*<div className="riyaqas-check-list media">*/}
                    {/*  <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />*/}
                    {/*  <span>Manage web apps more collaboratively</span>*/}
                    {/*</div>*/}
                    {/*<div className="riyaqas-check-list media mb-0">*/}
                    {/*  <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />*/}
                    {/*  <span>We are committed to deliver fast</span>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="d_tabs_5">
            <div className="single-hosting-content">
              <div className="row">
                <div className="col-lg-3">
                  <div className="section-title style-two">
                    <h6 className="title">QA <span>Testing</span></h6>
                  </div>
                  <p>Our software testing and quality assurance services ensure high-quality products that meet customers' requirements. With a focus on early defect identification, we save time and money, enhance customer experience, prevent breakdowns, and anticipate potential issues, thereby strengthening your brand and boosting customer satisfaction.</p>
                  <p></p>
                </div>
                <div className="col-lg-4">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/others/20.png"} alt="img" />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="riyaqas-check-list-wrap">
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Functional Testing</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Non-Functional Testing</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Manual Testing</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Automated Testing</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>API Testing</span>
                    </div>
                    {/*<div className="riyaqas-check-list media">*/}
                    {/*  <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />*/}
                    {/*  <span>Powered by top of the line enterprise</span>*/}
                    {/*</div>*/}
                    {/*<div className="riyaqas-check-list media mb-0">*/}
                    {/*  <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />*/}
                    {/*  <span>Manage web apps more collaboratively</span>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>    
    </div>
  </div>
</div>

        </div>
    }
}

export default Hosting